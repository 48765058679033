@keyframes slideInFromLeft {
  0% {
    transform: translate3d(-100%,-50%,-1000px);
  }
  100% {
    transform: translate3d(0);
  }
}

body, html {
  height: 100%;
  color: black;
}

p, h1, h3, h4 {  
	margin: 0;
	padding: 0;
	border: 0;
	font-style: normal;
	font-size: 100%;
  font-family: inherit;
}

h1, h2, h3 {
  line-height: 1;
}

body {
  font-family: 'Cinzel', serif;
}

.standard {
  background-color: #aaa9a9;
}

.fancy {  
  font-family: 'Great Vibes', cursive;
}

h1 {  
  font-size: 6em;
  line-height: 1.6;
}

h2 {
  font-size: 4.5em;
}

h3 {
  font-size: 3.5em;
}

h4 {
  font-size: 2em;
}

h5 {
  font-size: 1.7em;
}

p {
  line-height: 1.4;
  padding: 8px;
}

.Logo {  
  height: 100%;
}

.Logo img {
  height: 60px;
}

.center {
  top: 50%;
  text-align: center;
}

.magic-text {
  text-shadow: rgb(255, 255, 255) 3px 3px 0px, rgba(0, 0, 0, 0.2) 6px 6px 0px;
}

.fantasy-text {
  color:#000000;
  text-shadow: rgb(228, 228, 228) 1px 2px 0px, rgba(0, 0, 0, 0.2) 2px 3px 3px;
}

.work-section {
  background-image: url('./images/streetbattle.png');
  background-size: cover;
  border-radius: 5px;
  height: 680px;
}

.work-section img {
  width: 100%;
}

.work-header {
  text-align: left;
  position: absolute;
  color:#fce8af;
  text-shadow: #30343f 2px 0px 5px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 7px;
  padding-left: 130px;
  padding-right: 25px;
  z-index: 100;
}
.work-text {
  font-family: 'Times New Roman', Times, serif;
  font-size: 20px;
  font-weight: 300;
  width: 80%;
  max-width: 940px;
  text-align: center;
  position: absolute;
  color:#fce8af;
  text-shadow: #28304d 1px 1px 5px, #000000 2px 2px 1px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 80px;
  padding-left: 200px;
  padding-right: 25px;
  z-index: 100;
}
.mason-text {
  font-size: 22px;
  position: absolute;
  bottom: 40px;
  left: 40px;
  color:#fce8af;
  text-shadow: #30343f 2px 0px 5px;
}
.nav-link {
  font-size: 19px;
}

.shade-box {
  /* width: 550px; */
  box-shadow: var(--shd,0 1px 4px rgba(0,0,0,.6));
  border-radius: var(--rd,5px);
  background: rgba(246, 246, 246, 0.2);
}

.primary-image-fit {
  max-height: 100%;
  object-fit: contain;
}


.header-one {
    padding: 50px;
    width: 100%;
    background: linear-gradient(135deg, #aaf 0%, #fff 100%);
}

.header-two {
    padding: 50px;
    width: 100%;
    background: linear-gradient(135deg, #ffa 0%, #fff 100%);
}

.double-header {
  padding: 130px 0px 0px 0px;
}

.bg-city {
  background-image: url('./images/fantasyCity1.jpg');
  height: 951px;
}

.bg-home {
  background-image: url('./images/fantasymountains4.png');
}

.about-img-1 {
  position: relative;
  top: 20px;
  left: 40px;
}

ul {
  list-style: none outside none;
  display: block;
  padding: 0;
}

li {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: normal;
  font-style: normal;
  font-size: 100%;
  line-height: 1;
  font-family: inherit;
}

.slideInFirst {
  animation: slideInFromLeft 1.2s ease-out;
}
.slideInSecond {
  animation: slideInFromLeft 1s ease-out 1s;
}
.slideInThird {
  animation: slideInFromLeft 1s ease-out 1.5s;
}

.bg-image {  
	background-repeat: no-repeat;
	background-size: cover; /* 100%; /* 2138.09px 1202.4px; */
	background-color: transparent;
	background-position: center;
  background-attachment: fixed;
  padding-top: 50px;
  padding-bottom: 20%;
	min-height: 0;	
  height: 100%; 
}

.z-five {
  position: relative;
  z-index: 5;
}

.head-image {  
  background-image: url(images/mtn-1a.jpg);
  padding-top: 420px;
  padding-bottom: 460px;
  background-position: center;
  background-size: cover;
}

.tail-image {
  background-image: url(images/farm-3.jpg);
  padding-top: 150px;
  padding-bottom: 1340px;
}

.middle-image {
  padding-top: 350px;
  padding-bottom: 350px;
}

.left-nav {
  position: fixed;
  top: 0px;
  padding: 60px 0px 0px;
}

.sandbar {
  opacity: 0.8;
  position: fixed;
  top: 5%;
  left: 10px;
  width: 700px;
  z-index: 1;
  max-width: 100%;
}

.snow {
  opacity: 0.7;
  position: fixed;
  top: 30%;
  right: 10px;
  width: 700px;
  z-index: 2;
  max-width: 100%;
}

.ocean {
  opacity: 0.9;
  position: fixed;
  top: 47%;
  left: 30px;
  width: 800px;
  z-index: 3;
  max-width: 100%;
}

.farm-1 {
  border-radius: 35px;
  float: left;
  position: relative;
  width: 500px;
  transition: width 0.6s ease-out 0.05s;
  max-width: 100%;
  z-index: 11;
}
.farm-1:hover {
  width: 600px;
}

.farm-2 {
  border-radius: 35px;
  float: right;
  position: relative;
  width: 680px;
  transition: width 0.6s ease-out 0.05s;
  max-width: 100%;
  z-index: 12;
}
.farm-2:hover {
  width: 830px;
}

.river-1 {
  border-radius: 25px;
  float: left;
  position: relative;
  width: 700px;
  /* top: 710px; */
  transition: width 0.6s ease-out 0.05s;
  max-width: 100%;
  z-index: 13;
}
.river-1:hover {
  width: 830px;
}

.dot {
  height: 20px;
  width: 20px;
  background-color: rgb(53, 155, 49);
  text-indent: -999em;
  border-radius: 50%;
  margin: 18px auto;
  box-shadow: 3px 3px rgb(134, 12, 12);
  outline: none;
  cursor: pointer;
}

.smalldot {
  height: 12px;
  width: 12px;
}

.dot:hover {
  height: 25px;
  width: 25px;
  background-color: rgb(26, 83, 24);
  border-radius: 50%;
}

.navigator {
  position: fixed;
  z-index: 9999;
  top: 45%;
  right: 1.5%;
}

.transition {
	transition: padding-bottom .5s;
}

.section {
	padding: 80px 0;
	line-height: 1.4;
	color: #505c56; /* #060f28 */
}

.component-content {
  overflow: hidden;
  text-align: center;
}

.component-content::after, .component-content::before {
	content: "";
	position: relative;
  vertical-align: middle;
	height: 2px;
	width: 70px;
	margin: 0 10px;
  display: inline-block;
  background-color: #333;
}
.component-content::before {
	right: 0.5em;
  margin-left: -50%;
}
.component-content::after {
	left: 0.5em;
  margin-right: -50%;
}
/* h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, cite, code, del, dfn, em, img, q, s, samp, small, strike, strong, sub, sup, tt, var, dd, dl, dt, li, ol, ul, fieldset, form, label, input, textarea, legend, button, table, caption, tbody, tfoot, thead, tr, th, td { */

.box-shade {
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 3px 3px 15px rgba(255, 255, 255, 0.5);
  border-radius: 30px;
}

.adventure-button {
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  width: 9%;
}

@media only screen and (max-device-width: 1366px) {
  .bg-image {
    background-attachment: scroll;
  }
}

@media all and (max-width: 1218px) {
  .farm-2, .river-1 {
    left: 10px;
  }
}

@media all and (max-width: 1885px) {
  .river-1 {
    top: 30px;
  }
}

@media only screen and (max-width: 600px) {
  .head-image {
    padding-top: 180px;
    padding-bottom: 100px;
  }
  .about-image {
    padding-top: 320px;
    padding-bottom: 350px;
  }
  .bg-image {
    padding-top: 120px;
    padding-bottom: 350px;
  }
  .mobile-display-none {
    display: none;
    height: 0px;
  }
  .bg-home {
    background-repeat:repeat-y;
    background-size: cover;
    height: 2700px;
  }
  .snow {
    display: none;
  }
  
}

@media only screen and (max-width: 992px) {
  .work-images {
    display: none;
    height: 0px;
  }
  .work-text {
    width: 100%;
    padding-top: 80px;
    padding-left: 50px;
    padding-right: 50px;
  }
  .mason-text {
    display: none;
    height: 0px;
  }
}

@media only screen and (max-width: 810px) {
  .adventure-button {
    width: 15%;
  }
}

@media only screen and (max-width: 650px) {
  .work-header {
    padding-left: 40px;
    font-size: 2.4em;
  }
  .bottom-text {
    font-size: 0.6em;
  }
}

/* .card {
  margin-top: 15px;
  margin-bottom: 15px;
}

.card-title {
  display: inline-block;
}

.btn.delete {
  line-height: 1.0;
  padding: 0.25rem;
}

.btn-over, .btn-over:focus {
  box-shadow: 2px 3px 5px #111111;
} */

.Loader {
  color: #0000a0;
  font-size: 90px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 72px auto;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}
@-webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

